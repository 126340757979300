import React, { useState, useRef } from 'react';
import { Text, StyleSheet, ScrollView, View, TouchableOpacity } from 'react-native';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Privacy from './Privacy.js';
import Terms from './Terms.js';
import Guidelines from './Guidelines.js';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 0,
    padding: 0,
    height: '100vh', // Ensure the container takes the full height of the viewport
  },
  menuContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 18,
    backgroundColor: 'black',
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  menuItem: {
    fontSize: 18,
    color: 'white',
    fontFamily: 'Courier',
  },
  mainView: {
    flex: 1,
    // borderColor: 'red',
    borderWidth: 1,
    marginTop: 70, // Ensure content is not hidden behind the fixed header
    paddingTop: 18, // Add padding to avoid content touching the border
  },
});

function App() {
  const scrollViewRef = useRef();

  const openEmail = () => {
    window.location.href = 'mailto:laurentdelorme.perso@gmail.com';
  };

  return (
    <Router>
      <View style={styles.container}>
        <View style={styles.menuContainer}>
          <Link to="/">
            <Text style={styles.menuItem}>Home</Text>
          </Link>

          <Link to="/terms">
            <Text style={styles.menuItem}>Terms</Text>
          </Link>

          <Link to="/privacy">
            <Text style={styles.menuItem}>Privacy</Text>
          </Link>

          <Link to="/guidelines">
            <Text style={styles.menuItem}>Guidelines</Text>
          </Link>

          <TouchableOpacity onPress={openEmail}>
            <Text style={styles.menuItem}>Contact</Text>
          </TouchableOpacity>
        </View>
        
        <ScrollView ref={scrollViewRef} style={styles.mainView}>
          <Routes>
            <Route path="/" element={<Text>Hello World</Text>} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/guidelines" element={<Guidelines />} />
          </Routes>
        </ScrollView>
      </View>
    </Router>
  );
}

export default App;